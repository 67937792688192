// 城市默认配置
export const defaultCityOptions = [
  {
    name: '重庆市',
    children: [
      {
        name: '市辖区',
        children: [
          {
            name: '北碚区',
            children: [
              {
                name: '朝阳街道'
              }
            ]
          }
        ]
      }
    ]
  }
]

export const defaultCityValue = ['重庆市', '市辖区', '北碚区', '朝阳街道']
